@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", sans-serif;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p + p {
  margin-top: 0px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  -ms-overflow-style: none !important;
  /* Internet Explorer 10+ */
  scrollbar-width: none !important;
  /* Firefox */
}
.select-search-options div::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
}

body::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
}

body {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.select-search-container {
  --select-search-background: #fff !important;
  --select-search-border: #dce0e8 !important;
  --select-search-selected: #3b82f6 !important;
  --select-search-text: #000 !important;
  --select-search-subtle-text: #6c6f85 !important;
  --select-search-inverted-text: var(--select-search-background) !important;
  --select-search-highlight: #eff1f5 !important;
  --select-search-font: "Inter", sans-serif !important;
  width: 100% !important;
  height: 46px;

  position: relative !important;
  font-family: "Inter", sans-serif;
  color: #000 !important;
  box-sizing: border-box !important;
}

@media (prefers-color-scheme: dark) {
  .select-search-container {
    --select-search-background: #fff !important;
    --select-search-border: #dce0e8 !important;
    --select-search-selected: #3b82f6 !important;
    --select-search-text: #000 !important;
    --select-search-subtle-text: #6c6f85 !important;
    --select-search-inverted-text: var(--select-search-background) !important;
    --select-search-highlight: #eff1f5 !important;
    --select-search-font: "Inter", sans-serif !important;
    width: 100% !important;
    position: relative !important;
    font-family: "Inter", sans-serif;
    color: #000 !important;
    box-sizing: border-box !important;
  }
}

.select-search-input {
  border: 1px solid var(--select-search-border) !important;
  border-radius: 8px !important;
  height: 46px !important;
}

.overflow-hidden-body {
  overflow: hidden !important;
  height: 100vh;
}

.react-checkbox-tree {
  padding: 10px 5px;
}

.react-datepicker__day--selected {
  background-color: rgb(34, 169, 34) !important;
}

.react-checkbox-tree > ol > li {
  list-style: none;
  padding: 10px 0px;
  border-bottom: 1px solid #f3f4f6;
}

.react-checkbox-tree > ol > li > span > label {
  display: flex;
  gap: 3px;
  accent-color: #3b82f6;
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}

.rs-picker-menu {
  z-index: 99 !important;
}

.react-transform-component,
.react-transform-wrapper {
  height: 100vh !important;
  max-height: calc(100vh - 120px);
  width: 100% !important;
  justify-content: center;
}

.highlight-background {
  background-color: #fff;
  animation-name: backgroundHighlight;
  animation-duration: 2s;
}

.spin {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes backgroundHighlight {
  0% {
    background-color: #fff;
  }
  20% {
    background-color: rgb(229 242 251);
  }
  40% {
    background-color: #fff;
  }
  60% {
    background-color: rgb(229 242 251);
  }
  80% {
    background-color: #fff;
  }
  100% {
    background-color: rgb(229 242 251);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.select-search-is-disabled > div > input {
  background-color: rgb(243 244 246);
  color: rgb(107 114 128);
}
.select-search-value > input {
  font-size: 12px;
}
.react-time-picker__wrapper {
  border: 1px solid rgb(209 213 219) !important;
  border-radius: 16px !important;
  padding: 8px 12px !important;
  width: calc(100vw - 32px) !important;
  font-size: 16px !important;
}
.react-time-picker__button__icon {
  stroke: #3b82f6 !important;
  stroke-width: 2.2px !important;
  height: 21px !important;
  width: 21px !important;
}
.react-time-picker__inputGroup__amPm {
  -webkit-appearance: none !important;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(141 137 137 / 70%);
  z-index: 9999;
}

#status {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin: -20px 0 0 -20px;
}

.spinner {
  .spin-icon {
    font-size: 56px;
    color: blue;
    position: relative;
    display: inline-block;
    animation: spin 1.6s infinite linear;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="text"]::placeholder {
  text-transform: capitalize;
}

input {
  color: black !important;
}
